import React, { useState, useEffect } from "react";
import { Card, Typography, Spinner } from "@material-tailwind/react";
import { useLocation } from "react-router-dom"; // ใช้สำหรับดึง query parameters

import QrCode from "../assets/qrcode.jpg";
import config from "../config";
import Team from "../components/Team";

function PayNow({ matchups, fetchMatchups }) {
  const location = useLocation();
  const [loading, setLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [searchTerm, setSearchTerm] = useState("");
  const [matchupsPrice, setMatchupsPrice] = useState([]);
  const [shuttlecockCost, setShuttlecockCost] = useState(null);
  const [courtPrice, setCourtPrice] = useState(null);
  const [discountPrice, setDiscountPrice] = useState(null);
  const matchesPerPage = 100;

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const name = queryParams.get("name");
    if (name) {
      setSearchTerm(name); // กำหนดค่าเริ่มต้นของ searchTerm
    }
  }, [location.search]);

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const options = { day: "2-digit", month: "short", year: "numeric" };
    return date.toLocaleDateString("en-GB", options).replace(/ /g, " ");
  };

  useEffect(() => {
    fetchPayMatches();
  }, []);

  const handleFetchMatchups = async () => {
    setLoading(true);
    await fetchMatchups();
    setLoading(false);
  };

  const filteredMatchups = matchups.filter(
    (matchup) =>
      matchup.team1.some(
        (player) =>
          player?.name &&
          player.name.normalize("NFC") === searchTerm.normalize("NFC")
      ) ||
      matchup.team2.some(
        (player) =>
          player?.name &&
          player.name.normalize("NFC") === searchTerm.normalize("NFC")
      )
  );
  const filteredMatchupsPrice = matchupsPrice.filter(
    (matchup) =>
      matchup.team1.some(
        (player) =>
          player?.name &&
          player.name.normalize("NFC") === searchTerm.normalize("NFC")
      ) ||
      matchup.team2.some(
        (player) =>
          player?.name &&
          player.name.normalize("NFC") === searchTerm.normalize("NFC")
      )
  );

  useEffect(() => {
    let foundDiscount = null;

    const filteredMatchupsPrice = matchupsPrice.filter((matchup) => {
      const team1Matches = matchup.team1.some((player) => {
        if (
          player?.name &&
          player.name.normalize("NFC") === searchTerm.normalize("NFC")
        ) {
          if (foundDiscount === null) {
            foundDiscount = player.player_discount;
          }
          return true;
        }
        return false;
      });

      const team2Matches = matchup.team2.some((player) => {
        if (
          player?.name &&
          player.name.normalize("NFC") === searchTerm.normalize("NFC")
        ) {
          if (foundDiscount === null) {
            foundDiscount = player.player_discount;
          }
          return true;
        }
        return false;
      });

      return team1Matches || team2Matches;
    });

    setDiscountPrice(foundDiscount); // ตั้งค่าหลังจากกรองเสร็จ
    console.log(filteredMatchupsPrice); // คู่ที่ match
  }, [matchupsPrice, searchTerm]);
  
  console.log("DISCOUNT =>", discountPrice);

  if (filteredMatchupsPrice.length > 0) {
    console.log("match =>", filteredMatchupsPrice);
  }

  const getCurrentDate = () => {
    const today = new Date();
    const year = today.getFullYear();
    const month = String(today.getMonth() + 1).padStart(2, "0");
    const day = String(today.getDate()).padStart(2, "0");

    return `${year}-${month}-${day}`;
  };

  const fetchPayMatches = async () => {
    let currentDate = getCurrentDate();
    try {
      const response = await fetch(
        "https://admin.connect2play.site/pay_matches",
        // "http://localhost:9998/pay_matches",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            date: currentDate,
            company_id: config.company_id,
          }),
        }
      );
      if (!response.ok) {
        throw new Error("Failed to fetch matches");
      }
      const data = await response.json();
      setMatchupsPrice(data.all_matches);
      setCourtPrice(data.court_price);
      setShuttlecockCost(data.shuttlecock_price);
    } catch (err) {
      console.error(err);
    }
  };

  const totalShuttlecock = filteredMatchupsPrice.reduce(
    (total, matchup) => total + Number(matchup.shuttlecock), // แปลง string เป็น number
    0
  );
  const totalShuttlecockPrice = filteredMatchupsPrice.reduce(
    (total, matchup) => total + Number(matchup.shuttlecock) * shuttlecockCost,
    0
  );
  let totalPrice = (Number(totalShuttlecockPrice) + Number(courtPrice));

  if (discountPrice && Number(discountPrice) <= totalPrice) {
      totalPrice -= Number(discountPrice);
  } else if (discountPrice && Number(discountPrice) > totalPrice) {
      totalPrice = 0;
  } else {
      totalPrice = totalPrice;
  }
  

  // Pagination logic
  const indexOfLastMatch = currentPage * matchesPerPage;
  const indexOfFirstMatch = indexOfLastMatch - matchesPerPage;
  const currentMatches = matchups.slice(indexOfFirstMatch, indexOfLastMatch);
  const totalPages = Math.ceil(matchups.length / matchesPerPage);
  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  return (
    <div className="min-h-screen min-w-full flex flex-col bg-gradient-to-b from-sky-950 to-amber-900 py-6 sm:py-12 px-2 items-center">
      <div className="flex gap-10 flex-row max-md:flex-col-reverse">
        {/* <div className="grid grid-cols-1 sm:grid-cols-12 gap-4 w-full sm:w-3/4"> */}
        <div className="col-span-12 sm:col-span-8">
          <Card className="h-auto p-4 sm:p-6 bg-white shadow-md">
            {/* <div className="flex justify-between items-center mb-2 w-full">
              <h1 className="pl-2 pt-2 font-bold text-center text-xl max-2xl:text-lg max-xl:text-lg max-lg:text-lg">
                Pay Now
              </h1>
              <div className="flex justify-center mb-4 mt-6">
                <div className="w-full max-w-lg min-w-[200px]">
                  <div className="relative">
                    <input
                      type="text"
                      value={searchTerm}
                      onChange={(e) => setSearchTerm(e.target.value.trim())}
                      className="w-full pl-3 pr-10 py-2 bg-transparent placeholder:text-slate-400 text-slate-600 text-sm border border-slate-200 rounded-md transition duration-300 ease focus:outline-none focus:border-slate-400 hover:border-slate-300 shadow-sm focus:shadow"
                      placeholder="ค้นหารายชื่อ..."
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="border-b border-solid border-gray-200 my-4 w-full border-2"></div> */}
            <div className="flex flex-col w-full">
              <div className="flex justify-between items-center mb-4 w-full px-[68px] max-2xl:px-[4px] max-xl:px-[4px] max-lg:px-[4px]">
                <h1 className="text-xl sm:text-2xl font-bold text-left">
                  Pay Now
                </h1>
                <button
                  onClick={handleFetchMatchups}
                  className="ml-auto px-2 py-1"
                  disabled={loading}
                >
                  {loading ? (
                    <Spinner animation="border" size="sm" />
                  ) : (
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      strokeWidth={1.5}
                      stroke="currentColor"
                      className="size-5"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M16.023 9.348h4.992v-.001M2.985 19.644v-4.992m0 0h4.992m-4.993 0 3.181 3.183a8.25 8.25 0 0 0 13.803-3.7M4.031 9.865a8.25 8.25 0 0 1 13.803-3.7l3.181 3.182m0-4.991v4.90"
                      />
                    </svg>
                  )}
                </button>
              </div>
              <div className="border-b border-solid border-gray-200 mb-4 w-full border-2 mx-auto"></div>
              {filteredMatchups.length > 0 ? (
                filteredMatchups.map((matchup) => (
                  <div className="px-16 max-2xl:px-0 max-xl:px-0 max-lg:px-0 mb-1 max-2xl:mb-1 max-xl:mb-3 max-lg:mb-3">
                    <div className="flex flex-row items-center justify-between w-full">
                      <div>
                        <div className="flex mb-2">
                          <p className="flex justify-center items-center font-bold text-[16px] max-2xl:text-[14px] max-xl:text-[14px] max-lg:text-[12px] max-md:text-[10px] max-sm:text-[6px] bg-gray-800 text-white px-4 ml-1 mr-1 w-[180px] max-2xl:w-[170px] max-xl:w-[160px] max-lg:w-[130px] max-md:w-[100px] max-sm:w-[62px] py-[2px] max-2xl:py-[2px] max-xl:py-[2px] max-lg:py-[2px]">
                            COURT {matchup.courtNumber}
                          </p>
                          <p
                            className={`uppercase flex justify-center items-center font-bold text-[16px] max-2xl:text-[14px] max-xl:text-[14px] max-lg:text-[12px] max-md:text-[10px] max-sm:text-[8px] ml-1 py-[2px] max-2xl:py-[2px] max-xl:py-[2px] max-lg:py-[2px] ${matchup.match_status === 'playing'
                              ? 'bg-orange-600 w-[180px] max-2xl:w-[170px] max-xl:w-[160px] max-lg:w-[130px] max-md:w-[100px] max-sm:w-[62px]'
                              : matchup.match_status === 'waiting'
                                ? 'bg-gray-500 w-[180px] max-2xl:w-[170px] max-xl:w-[160px] max-lg:w-[130px] max-md:w-[100px] max-sm:w-[62px]'
                                : matchup.match_status === 'completed'
                                  ? 'bg-green-600 w-[180px] max-2xl:w-[170px] max-xl:w-[160px] max-lg:w-[130px] max-md:w-[100px] max-sm:w-[62px]'
                                  : 'bg-gray-800'
                              } text-white`}
                          >
                            {matchup.match_status}
                          </p>
                        </div>
                        <Team
                          players={matchup.team1}
                          teamColor="red"
                          // imageMaxWidth="w-[180px] max-2xl:w-[150px] max-xl:w-[140px] max-lg:w-[70px]"
                          imageMaxWidth="w-[180px] max-2xl:w-[170px] max-xl:w-[160px] max-lg:w-[130px] max-md:w-[100px] max-sm:w-[62px]"
                          imageMaxHeight="h-[180px] max-2xl:h-[170px] max-xl:h-[160px] max-lg:h-[130px] max-md:h-[100px] max-sm:h-[62px]"
                          fontSize="text-[16px] max-2xl:text-[16px] max-xl:text-[14px] max-lg:text-[12px] max-md:text-[10px] max-sm:text-[9px]"
                          imageMarginX="mx-0.5"
                          borderWeight="border-4 max-lg:border-2"
                          stateMatch={matchup.match_status}
                        />
                      </div>

                      <div>
                        <div className="flex items-center justify-center text-center mb-2 mt-4">
                          <span
                            className="font-bold italic text-transparent bg-clip-text bg-gradient-to-r from-gray-800 to-orange-800 transition-all duration-500 px-0 text-5xl max-2xl:text-4xl max-xl:text-3xl max-lg:text-3xl max-md:text-2xl max-sm:text-xl w-[140px] max-2xl:w-[100px] max-xl:w-[55px] max-lg:w-[65px] max-md:w-[60px] max-sm:w-[40px]"
                          >
                            VS
                          </span>
                        </div>
                        <div className="flex items-center justify-center text-center">
                          <p className="font-bold text-transparent bg-clip-text bg-gradient-to-r from-gray-800 to-orange-800 transition-all duration-500 text-[14px] max-2xl:text-[14px] max-xl:text-[14px] max-lg:text-[14px] max-md:text-[12px] max-sm:text-[10px] px-1">
                            GAME {matchup.match_order}
                          </p>
                        </div>
                      </div>
                      <div className="flex flex-col items-center justify-center">
                        <div className="uppercase font-bold text-gray-500 mb-2 text-[16px] max-2xl:text-[14px] max-xl:text-[14px] max-lg:text-[12px] max-md:text-[10px] max-sm:text-[8px] flex flex-row gap-4 bg-gray-800 text-white justify-center items-center w-full py-[2px] max-2xl:py-[2px] max-xl:py-[2px] max-lg:py-[2px]">
                          <p>{formatDate(matchup.date)}</p>
                          <p>{matchup.startTime}</p>
                        </div>
                        <Team
                          players={matchup.team2}
                          teamColor="blue"
                          imageMaxWidth="w-[180px] max-2xl:w-[170px] max-xl:w-[160px] max-lg:w-[130px] max-md:w-[100px] max-sm:w-[62px]"
                          imageMaxHeight="h-[180px] max-2xl:h-[170px] max-xl:h-[160px] max-lg:h-[130px] max-md:h-[100px] max-sm:h-[62px]"
                          fontSize="text-[16px] max-2xl:text-[16px] max-xl:text-[14px] max-lg:text-[12px] max-md:text-[10px] max-sm:text-[9px]"
                          imageMarginX="mx-0.5"
                          borderWeight="border-4 max-lg:border-2"
                          stateMatch={matchup.match_status}
                        />
                      </div>
                    </div>
                    {matchup.courtNumber <= matchups.length && (
                      <div className="border-b border-solid border-gray-200 mt-2 mb-2 w-full border-2" />
                    )}
                  </div>
                ))
              ) : (
                <p className="text-center text-gray-500">
                  No matchups available.
                </p>
              )}
            </div>
            <div className="flex justify-center items-center mt-4 space-x-2">
              {/* ปุ่ม First Page */}
              <button
                onClick={() => paginate(1)}
                disabled={currentPage === 1}
                className="px-3 py-2 rounded border border-orange-300 text-orange-500 hover:bg-orange-100 disabled:opacity-50"
              >
                &laquo;&laquo;
              </button>

              {/* ปุ่ม Previous */}
              <button
                onClick={() => paginate(currentPage - 1)}
                disabled={currentPage === 1}
                className="px-3 py-2 rounded border text-gray-500 hover:bg-gray-100 disabled:opacity-50"
              >
                &lsaquo;
              </button>

              {Array.from({ length: 4 }, (_, index) => {
                let pageNumber = currentPage - 2 + index;

                // ปรับให้ pageNumber ไม่ต่ำกว่า 1 และไม่เกิน totalPages
                if (currentPage <= 2) {
                  pageNumber = index + 1; // แสดง 1-4 เมื่ออยู่หน้าต้นๆ
                } else if (currentPage >= totalPages - 1) {
                  pageNumber = totalPages - 3 + index; // แสดงหน้าสุดท้ายย้อนกลับ 4 ลำดับ
                }

                if (pageNumber > 0 && pageNumber <= totalPages) {
                  return (
                    <button
                      key={pageNumber}
                      onClick={() => paginate(pageNumber)}
                      className={`px-4 py-2 rounded border ${currentPage === pageNumber
                        ? "bg-blue-500 text-white" // ปุ่มหน้าปัจจุบัน
                        : "bg-gray-100 text-gray-700 hover:bg-gray-200"
                        }`}
                    >
                      {pageNumber}
                    </button>
                  );
                }
                return null;
              })}

              {/* ปุ่ม Next */}
              <button
                onClick={() => paginate(currentPage + 1)}
                disabled={currentPage === totalPages}
                className="px-3 py-2 rounded border text-gray-500 hover:bg-gray-100 disabled:opacity-50"
              >
                &rsaquo;
              </button>

              {/* ปุ่ม Last Page */}
              <button
                onClick={() => paginate(totalPages)}
                disabled={currentPage === totalPages}
                className="px-3 py-2 rounded border border-orange-300 text-orange-500 hover:bg-orange-100 disabled:opacity-50"
              >
                &raquo;&raquo;
              </button>
            </div>
          </Card>
        </div>
        <div className="col-span-12 sm:col-span-4">
          <Card className="h-auto p-8 sm:p-6 bg-white shadow-lg rounded-lg flex flex-col ">
            {/* Summary Section */}
            <div className="mb-4 sm:mr-6">
              <p className="text-2xl font-extrabold text-gray-800 mb-4 border-b-2 pb-2 border-gray-200">
                สรุปราคา
              </p>
              <div className="space-y-2 text-gray-600">
                <p>
                  <span className="font-semibold">ค่าสนาม:</span> {courtPrice}{" "}
                  บาท
                </p>
                <p>
                  <span className="font-semibold">จำนวน:</span>{" "}
                  {totalShuttlecock} ลูก ({filteredMatchupsPrice.length} เกม) ={" "}
                  {totalShuttlecockPrice} บาท
                </p>
                {discountPrice > 0 && (
                  <p>
                    <span className="font-semibold">ส่วนลด:</span> {discountPrice} บาท
                  </p>
                )}
              </div>
              <p className="text-xl font-bold text-green-600 mt-4">
                รวม: {totalPrice} บาท
              </p>
            </div>

            {/* QR Code Section */}
            <div className="flex flex-col justify-center items-center">
              <img
                id="qrCodeImage"
                src={QrCode}
                alt="QR Code"
                className="object-contain w-[360px] max-2xl:w-[300px] max-xl:w-[275px] max-lg:w-[200px] max-md:w-[260px] max-sm:w-[260px] rounded-lg border border-gray-300 shadow-sm"
              />
              {/* Save Button */}
              <a
                href={QrCode}
                download="QRCode-Payment.png"
                className="mt-4 bg-blue-400 text-white px-4 py-2 rounded-lg shadow hover:bg-blue-600 transition"
              >
                บันทึก QR Code
              </a>
            </div>
          </Card>
        </div>
      </div>
    </div>
  );
}

export default PayNow;
