import React, { useState, useEffect } from "react";
import Team from "../components/Team";
import { Card, Spinner } from "@material-tailwind/react";

function Match({ matchups, fetchMatchups }) {
    const [loading, setLoading] = useState(false);
    const [currentTime, setCurrentTime] = useState(new Date());
    const [currentPage, setCurrentPage] = useState(1);
    const [searchTerm, setSearchTerm] = useState("");
    const matchesPerPage = 10;

    const formatDate = (dateString) => {
        const date = new Date(dateString);
        const options = { day: "2-digit", month: "short", year: "numeric" };
        return date.toLocaleDateString("en-GB", options).replace(/ /g, " ");
    };

    useEffect(() => {
        const intervalId = setInterval(() => {
            setCurrentTime(new Date());
        }, 60000);

        return () => clearInterval(intervalId);
    }, []);

    const calculateWaitingTime = (date, startTime) => {
        const start = new Date(`${date} ${startTime}`);
        const diff = currentTime - start;
        const minutes = Math.floor((diff / 1000 / 60) % 60);
        const hours = Math.floor(diff / 1000 / 60 / 60);
        return `${hours}h ${minutes}m`;
    };

    const handleFetchMatchups = async () => {
        setLoading(true);
        await fetchMatchups();
        setLoading(false);
    };

    const handleSearchChange = (event) => {
        setSearchTerm(event.target.value ? event.target.value.toLowerCase() : "");
    };
    
    const filteredMatchups = matchups.filter((matchup) =>
        matchup.team1.some((player) =>
            player.name && player.name.toLowerCase().includes(searchTerm)
        ) ||
        matchup.team2.some((player) =>
            player.name && player.name.toLowerCase().includes(searchTerm)
        )
    );
    

    // Pagination logic
    const indexOfLastMatch = currentPage * matchesPerPage;
    const indexOfFirstMatch = indexOfLastMatch - matchesPerPage;
    const currentMatches = filteredMatchups.slice(indexOfFirstMatch, indexOfLastMatch);
    const totalPages = Math.ceil(filteredMatchups.length / matchesPerPage);
    const paginate = (pageNumber) => setCurrentPage(pageNumber);

    return (
        <div className="min-h-screen min-w-full flex flex-col bg-gradient-to-b from-sky-950 to-amber-900 py-6 sm:py-12 px-2 items-center">
            <div className="w-[1060px] max-2xl:w-[880px] max-xl:w-[770px] max-lg:w-[660px] max-md:w-[540px] max-sm:w-full">{/* <div className="grid grid-cols-1 sm:grid-cols-12 gap-4 w-full sm:w-3/4"> */}
                <div className="col-span-12 sm:col-span-8">
                    <Card className="h-auto p-4 sm:p-6 bg-white shadow-md">
                        <div className="flex justify-between items-center mb-2 w-full max-sm:items-start">
                            <h1 className="italic pl-2 pt-2 font-bold text-center text-xl max-2xl:text-lg max-xl:text-lg max-lg:text-lg max-md:text-[18px] max-sm:text-[14px]">
                                2 VS 2 Matchups
                            </h1>
                            {/* <button
                                onClick={handleFetchMatchups}
                                className="ml-auto px-2 py-1"
                                disabled={loading}
                            >
                                {loading ? (    
                                    <Spinner animation="border" size="sm" />
                                ) : (
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        fill="none"
                                        viewBox="0 0 24 24"
                                        strokeWidth={1.5}
                                        stroke="currentColor"
                                        className="size-5"
                                    >
                                        <path
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                            d="M16.023 9.348h4.992v-.001M2.985 19.644v-4.992m0 0h4.992m-4.993 0 3.181 3.183a8.25 8.25 0 0 0 13.803-3.7M4.031 9.865a8.25 8.25 0 0 1 13.803-3.7l3.181 3.182m0-4.991v4.99"
                                        />
                                    </svg>
                                )}
                            </button> */}
                            <div className="px-[10px] flex justify-between items-center">
                                <input
                                    type="text"
                                    placeholder="Search player..."
                                    value={searchTerm}
                                    onChange={handleSearchChange}
                                    className="border border-gray-300 px-8 py-2 text-sm focus:outline-none focus:ring-2 focus:ring-gray-200 w-[300px] max-2xl:w-[250px] max-xl:w-[240px] max-lg:w-[230px] max-md:w-[210px] max-sm:w-[160px] text-[16px] max-2xl:text-[14px] max-xl:text-[14px] max-lg:text-[12px] max-md:text-[12px] max-sm:text-[12px]"
                                />
                            </div>
                        </div>
                        <div className="border-b border-solid border-gray-200 my-4 w-full border-2"></div>
                        <div className="flex flex-col w-full">
                            {filteredMatchups.length === 0 ? (
                                <p className="text-center text-gray-500">
                                    No matchups available.
                                </p>
                            ) : (
                                currentMatches.map((matchup) => (
                                    <div className="px-16 max-2xl:px-0 max-xl:px-0 max-lg:px-0 mb-1 max-2xl:mb-1 max-xl:mb-3 max-lg:mb-3">
                                        <div className="flex flex-row items-center justify-between w-full">
                                            <div>
                                                <div className="flex mb-2">
                                                    <p className="flex justify-center items-center font-bold text-[16px] max-2xl:text-[14px] max-xl:text-[14px] max-lg:text-[12px] max-md:text-[10px] max-sm:text-[6px] bg-gray-800 text-white px-4 ml-1 mr-1 w-[180px] max-2xl:w-[170px] max-xl:w-[160px] max-lg:w-[130px] max-md:w-[100px] max-sm:w-[62px] py-[2px] max-2xl:py-[2px] max-xl:py-[2px] max-lg:py-[2px]">
                                                        COURT {matchup.courtNumber}
                                                    </p>
                                                    <p
                                                        className={`uppercase flex justify-center items-center font-bold text-[16px] max-2xl:text-[14px] max-xl:text-[14px] max-lg:text-[12px] max-md:text-[10px] max-sm:text-[7px] ml-1 py-[2px] max-2xl:py-[2px] max-xl:py-[2px] max-lg:py-[2px] ${matchup.match_status === 'playing'
                                                            ? 'bg-orange-600 w-[180px] max-2xl:w-[170px] max-xl:w-[160px] max-lg:w-[130px] max-md:w-[100px] max-sm:w-[62px]'
                                                            : matchup.match_status === 'waiting'
                                                                ? 'bg-gray-500 w-[180px] max-2xl:w-[170px] max-xl:w-[160px] max-lg:w-[130px] max-md:w-[100px] max-sm:w-[62px]'
                                                                : matchup.match_status === 'completed'
                                                                    ? 'bg-green-600 w-[180px] max-2xl:w-[170px] max-xl:w-[160px] max-lg:w-[130px] max-md:w-[100px] max-sm:w-[62px]'
                                                                    : 'bg-gray-800'
                                                            } text-white`}
                                                    >
                                                        {matchup.match_status}
                                                    </p>
                                                </div>
                                                <Team
                                                    players={matchup.team1}
                                                    teamColor="red"
                                                    // imageMaxWidth="w-[180px] max-2xl:w-[150px] max-xl:w-[140px] max-lg:w-[70px]"
                                                    imageMaxWidth="w-[180px] max-2xl:w-[170px] max-xl:w-[160px] max-lg:w-[130px] max-md:w-[100px] max-sm:w-[62px]"
                                                    imageMaxHeight="h-[180px] max-2xl:h-[170px] max-xl:h-[160px] max-lg:h-[130px] max-md:h-[100px] max-sm:h-[62px]"
                                                    fontSize="text-[16px] max-2xl:text-[16px] max-xl:text-[14px] max-lg:text-[12px] max-md:text-[10px] max-sm:text-[9px]"
                                                    imageMarginX="mx-0.5"
                                                    borderWeight="border-4 max-lg:border-2"
                                                    stateMatch={matchup.match_status}
                                                />
                                            </div>

                                            <div>
                                                <div className="flex items-center justify-center text-center mb-2 mt-4">
                                                    <span
                                                        className="font-bold italic text-transparent bg-clip-text bg-gradient-to-r from-gray-800 to-orange-800 transition-all duration-500 px-0 text-5xl max-2xl:text-4xl max-xl:text-3xl max-lg:text-3xl max-md:text-2xl max-sm:text-xl w-[140px] max-2xl:w-[100px] max-xl:w-[55px] max-lg:w-[65px] max-md:w-[60px] max-sm:w-[40px]"
                                                    >
                                                        VS
                                                    </span>
                                                </div>
                                                <div className="flex items-center justify-center text-center">
                                                    <p className="font-bold text-transparent bg-clip-text bg-gradient-to-r from-gray-800 to-orange-800 transition-all duration-500 text-[14px] max-2xl:text-[14px] max-xl:text-[14px] max-lg:text-[14px] max-md:text-[12px] max-sm:text-[10px] px-1">
                                                        GAME {matchup.match_order}
                                                    </p>
                                                </div>
                                            </div>
                                            <div className="flex flex-col items-center justify-center">
                                                <div className="uppercase font-bold text-gray-500 mb-2 text-[16px] max-2xl:text-[14px] max-xl:text-[14px] max-lg:text-[12px] max-md:text-[10px] max-sm:text-[8px] flex flex-row gap-4 bg-gray-800 text-white justify-center items-center w-full py-[2px] max-2xl:py-[2px] max-xl:py-[2px] max-lg:py-[2px]">
                                                    <p>{formatDate(matchup.date)}</p>
                                                    <p>{matchup.startTime}</p>
                                                </div>
                                                <Team
                                                    players={matchup.team2}
                                                    teamColor="blue"
                                                    imageMaxWidth="w-[180px] max-2xl:w-[170px] max-xl:w-[160px] max-lg:w-[130px] max-md:w-[100px] max-sm:w-[62px]"
                                                    imageMaxHeight="h-[180px] max-2xl:h-[170px] max-xl:h-[160px] max-lg:h-[130px] max-md:h-[100px] max-sm:h-[62px]"
                                                    fontSize="text-[16px] max-2xl:text-[16px] max-xl:text-[14px] max-lg:text-[12px] max-md:text-[10px] max-sm:text-[9px]"
                                                    imageMarginX="mx-0.5"
                                                    borderWeight="border-4 max-lg:border-2"
                                                    stateMatch={matchup.match_status}
                                                />
                                            </div>
                                        </div>
                                        {matchup.courtNumber <= matchups.length && (
                                            <div className="border-b border-solid border-gray-200 mt-2 mb-2 w-full border-2" />
                                        )}
                                    </div>

                                ))
                            )}
                        </div>
                        <div className="flex justify-center items-center mt-4 space-x-2">
                            {/* ปุ่ม First Page */}
                            <button
                                onClick={() => paginate(1)}
                                disabled={currentPage === 1}
                                className="px-3 py-2 rounded border border-orange-300 text-orange-500 hover:bg-orange-100 disabled:opacity-50"
                            >
                                &laquo;&laquo;
                            </button>

                            {/* ปุ่ม Previous */}
                            <button
                                onClick={() => paginate(currentPage - 1)}
                                disabled={currentPage === 1}
                                className="px-3 py-2 rounded border text-gray-500 hover:bg-gray-100 disabled:opacity-50"
                            >
                                &lsaquo;
                            </button>

                            {Array.from({ length: 4 }, (_, index) => {
                                let pageNumber = currentPage - 2 + index;

                                // ปรับให้ pageNumber ไม่ต่ำกว่า 1 และไม่เกิน totalPages
                                if (currentPage <= 2) {
                                    pageNumber = index + 1; // แสดง 1-4 เมื่ออยู่หน้าต้นๆ
                                } else if (currentPage >= totalPages - 1) {
                                    pageNumber = totalPages - 3 + index; // แสดงหน้าสุดท้ายย้อนกลับ 4 ลำดับ
                                }

                                if (pageNumber > 0 && pageNumber <= totalPages) {
                                    return (
                                        <button
                                            key={pageNumber}
                                            onClick={() => paginate(pageNumber)}
                                            className={`px-4 py-2 rounded border ${currentPage === pageNumber
                                                ? 'bg-blue-500 text-white' // ปุ่มหน้าปัจจุบัน
                                                : 'bg-gray-100 text-gray-700 hover:bg-gray-200'
                                                }`}
                                        >
                                            {pageNumber}
                                        </button>
                                    );
                                }
                                return null;
                            })}

                            {/* ปุ่ม Next */}
                            <button
                                onClick={() => paginate(currentPage + 1)}
                                disabled={currentPage === totalPages}
                                className="px-3 py-2 rounded border text-gray-500 hover:bg-gray-100 disabled:opacity-50"
                            >
                                &rsaquo;
                            </button>

                            {/* ปุ่ม Last Page */}
                            <button
                                onClick={() => paginate(totalPages)}
                                disabled={currentPage === totalPages}
                                className="px-3 py-2 rounded border border-orange-300 text-orange-500 hover:bg-orange-100 disabled:opacity-50"
                            >
                                &raquo;&raquo;
                            </button>
                        </div>

                    </Card>
                </div>
            </div>
        </div>
    );
}

export default Match;
