import React from "react";
import { useNavigate } from "react-router-dom";

function Team({
  players,
  imageMaxWidth = "max-xl:w-[150px]",
  imageMaxHeight = "max-xl:h-[150px]",
  // rotateDegree = "rotate-90",
  rotateDegree = "",
  teamColor = "red",
  stateMatch = "complete",
  fontSize = "text-base",
  borderWeight = "border-4"
}) {
  const defaultImage =
    "https://admin.connect2play.site/static/images/bright-icon-rotate.jpg";
  const navigate = useNavigate();

  const handleImageClick = (playerName) => {
    const formattedName = encodeURIComponent(playerName); // เข้ารหัสชื่อให้เหมาะกับ URL
    navigate(`/paynow?name=${formattedName}`);
  };

  // กำหนดสีตาม teamColor
  const borderColor =
    teamColor === "red" ? "border-red-700" : "border-blue-900";
  const textColor = teamColor === "red" ? "text-red-600" : "text-blue-800";
  const bgColor = teamColor === "red" ? "bg-red-700	" : "bg-blue-900";

  // กำหนดระดับความจาง
  const opacityStyle = stateMatch === "complete" ? "opacity-50" : "opacity-100";

  return (
    <div className="flex items-center">
      {players.map((player, index) => (
        <div key={index} className="flex flex-col items-center mx-1">
          <div
            className={`${imageMaxWidth} ${imageMaxHeight} ${rotateDegree} ${borderColor} ${borderWeight} overflow-hidden flex items-center justify-center cursor-pointer`}
            onClick={() => handleImageClick(player.name)}
            style={{
              backgroundImage: `url(${player.image && !player.image.endsWith("None") ? player.image : defaultImage})`,
              backgroundSize: "cover",
              backgroundPosition: "center",
            }}
          >
          </div>
          <span
            className={`mt-0 text-center font-bold ${textColor} ${fontSize} ${bgColor} text-white px-4 pb-[1px] ${imageMaxWidth} overflow-hidden truncate max-${imageMaxWidth}`}
          >
            {player.name}
          </span>

        </div>
      ))}
    </div>
  );
}

export default Team;
