import React, { useState, useEffect } from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import FooterWithLogo from "./components/FooterWithLogo";
import SimpleNavbar from "./components/SimpleNavbar";
import "./index.css";
import Match from "./pages/Match";
import PayNow from "./pages/PayNow";
import Contact from "./pages/Contact";
import config from "./config";

const formatDateThai = (dateString) => {
  const date = new Date(dateString);
  const options = { day: "2-digit", month: "long", year: "numeric" };
  const thaiYear = date.getFullYear() + 543;

  const formattedDate = new Intl.DateTimeFormat("th-TH", options).format(date);
  return formattedDate.replace(date.getFullYear(), thaiYear);
};

function App() {
  const [matchups, setMatchups] = useState([]);
  const fetchMatchups = async () => {
    try {
      const response = await fetch(
        "https://admin.connect2play.site/fetch_matches",
        // "http://localhost:9998/fetch_matches",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ company_id: config.company_id }),
        }
      );
      if (!response.ok) {
        throw new Error("Failed to fetch matches");
      }
      const data = await response.json();
      console.log("111 data222 =>", data.all_matches);
      setMatchups(data.all_matches);
    } catch (err) {
      console.log("111 ERR =>", err);
    }
  };

  useEffect(() => {
    fetchMatchups();
  }, []);

  return (
    <Router>
      <div className="flex flex-col min-h-screen">
        <SimpleNavbar />
        <main className="flex-grow flex items-center justify-center">
          <Routes>
            <Route
              path="/"
              element={
                <Match
                  matchups={matchups}
                  fetchMatchups={fetchMatchups}
                />
              }
            />
            <Route
              path="/match"
              element={
                <Match
                  matchups={matchups}
                  fetchMatchups={fetchMatchups}
                />
              }
            />
            <Route
              path="/paynow"
              element={
                <PayNow
                  matchups={matchups}
                  fetchMatchups={fetchMatchups}
                />
              }
            />
            <Route path="/contact" element={<Contact />} />
            <Route path="/paynow" element={<PayNow
              matchups={matchups}
              fetchMatchups={fetchMatchups} />} />
          </Routes>
        </main>

        <FooterWithLogo />
      </div>
    </Router>
  );
}

export default App;
